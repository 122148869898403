@import "../../assets/styles/constants.scss";

.page-visitor-container {
  display: grid;
  grid-template-rows: 1fr auto;
  margin: 1%;
}

.page-visitor-topbar {
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%;
  margin-top: 20px;
  margin-bottom: 5px;
}

.page-visitor-mainbar {
  margin-top: 20px;
  margin-bottom: 5px;
}

.page-visitor-mainbar-mobile {
  margin-top: 20px;
  margin-bottom: 60px;
}

.page-visitor-container-mobile {
  display: grid;
  grid-template-rows:  auto auto;
}

.page-visitor-topbar-mobile {
  margin-top: 19%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-column-gap: 2%;
  grid-row-gap: 5px;
}

.page-mainpart{
  padding-top: 5%;
}


.cardpage {
  width: 96%;
  margin-left: 2%;
  border: none;
}

.table-data{
  width: 98%;
  margin-left: 0%;
}

.buttonPicker{
  width: 33%;
}

.modalPicker{
  margin-top: 1.5%;
}

.first{
  margin-left: 16%;
}


.first1{
  margin-left: 33%;
}
@media(max-width: 600px){
  .page-mainpart{
    padding-top: 28%;
  }

  .buttonPicker{
    width: 50%;
  }

 .first{
    margin-left: 0%;
  }

  .first1{
    margin-left: 25%;
  }

  .modalPicker{
    margin-top: 6%;
  }
  // .table-data{
  //   width: 98%;
  //   margin-left: 1%;
  // }

  .cardpage{
    width: 98%;
    margin-left: 1%;
  }
}