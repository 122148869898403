@import "../../assets/styles/constants.scss";


.search-bar-container{
  @include shadow();
  display:grid;
  grid-template-columns: auto auto;
  height: auto;
  margin: 5px 5px;
}

.text-search{
  padding: 10px;
  outline: 0;
  border: none;
}

.btn-search{
  background: #fff;
  padding: 10px;
  outline: 0;
  border: none;
}


//dropdowns

.dropdown-basic-container {
  display: grid;
  grid-template-columns: 1.5fr 8.5fr;
  background: #fff;
  align-items: center;
  @include shadow();
  padding: 5px 2px;
  border-radius: 4px;
}

.dropdown-basic-icon-container {
  display: grid;
  align-content: center;
  justify-items: center;
  font-size: 25px;
}

// .dropdown-basic-title-data {

// }

// .dropdown-basic-title {
//   text-align: start;
//   font-size: 10px;
//   padding: 5px 10px;
// }

// .dropdown-basic-data {
//   padding: 2px 5px;
//   font-size: 14px;
// }

// .dropdown-basic-selection {
//   background: #fff;
//   border: none;
//   outline: 0;
//   width: 100%;
// }



a.ant-calendar-month-panel-next-year-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-month-panel-prev-year-btn{
  height: 10%;
  width: 10%;
}


a.ant-calendar-year-panel-next-decade-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-year-panel-prev-decade-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-decade-panel-next-century-btn{
  height: 10%;
  width: 10%;
}


a.ant-calendar-decade-panel-prev-century-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-next-year-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-prev-year-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-next-month-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-prev-month-btn{
  height: 10%;
  width: 10%;
}

a.ant-calendar-month-select{
  height: 10%;
  width: 10%;
}

a.ant-calendar-year-select{
  height: 10%;
  width: 10%;
}


