$appBackground: #eff1f1;



.app-container {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-columns: auto 95%;
}

// .app-sidebar {
//   background: #37474f;
// }

.app-mainbar {
  background:$appBackground;
  display: grid;
  grid-template-rows: 1fr 9fr;
}


.app-container-mobile {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: 10% 80% 10%;
}

// .app-sidebar {
//   background: #37474f;
// }

// .app-mainbar {
//   background: #37474f;
//   display: grid;
//   grid-template-rows: 1fr 9fr;
// }





.text-red {
  color: #fe3935;
}

.text-black {
  color: #37474f;
}

.text-blue {
  color: #0079bf;
}

.text-green {
  color: rgb(76, 196, 116);
}

.text-green-deep {
  color: rgb(69, 179, 105);
}
