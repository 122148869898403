// $blueColor: #0079bf;
$blueColor: #37474f;
$lightBlueColor: rgba(0, 121, 191, 0.541);

$redColor: #dc143c;
$lightRedColor: rgba(240, 128, 128, 0.432);

$orangeColor: #ff8c00;
$lightOrangeColor: rgba(255, 166, 0, 0.473);


$greenColor: rgb(27, 155, 112);
$lightGreenColor: rgb(168, 214, 193);


$whiteColor: #fff;
$greyColor: rgba(238, 238, 238, 0.493);
$lightGreyColor: rgba(238, 238, 238, 0);
$blackColor: #37474f;
$textRedColor: #fe3935;
$textBlueColor: #0079bf;
$textBlackColor: #37474f;
$appBackground: #eff1f1;
$magentaColor: #19b4b4;

.text-red {
  color: $textRedColor;
}

.text-black {
  color: $textBlackColor;
}

.text-blue {
  color: $textBlueColor;
}
.text-orange {
  color: $orangeColor;
}
.text-green {
  color: rgb(76, 196, 116);
}
.text-magenta {
  color: $magentaColor;
}

.text-green-deep {
  color: rgb(69, 179, 105);
}

.background-color {
  background: #eff1f1;
}

@mixin shadow {
  -webkit-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  -moz-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
}

@mixin gridTwoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}



//text input

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap:5px;
  // background: $greyColor;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404);
}

.icon-text-input-box {
  // background: $greyColor;
  font-size: 14px;
  margin-top: 10px;
}

.text-input-background {
  // background: $lightGreyColor;
  padding: 5px;
  border: none;
  border-radius: 5px;
  &:focus {
    outline: 0;
  }
}

.title-over-input{
  font-size: 12px;
  text-align: start;
}

.title-over-table{
  font-size: 25px;
  text-align: start;
  align-self: center;
}
