body{
    overflow-y: scroll;
}

#whiteBack{
    width: 82%;
    margin-left: 8%;
    border: 0px;
    padding: 2%;
}
.phoneLoginButton{
    width: 90%;
    margin-left: 5%;
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    color: white;
    border: 0px;
}

.phoneLoginButton:hover{
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    color: white;
    border: 0px;
}