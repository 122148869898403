@import "../../assets/styles/constants.scss";


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons .md-36{
  font-size: 36px;
}

.material-icons .md-18{
  font-size: 18px;
}

.material-icons .md-48{
  font-size: 48px;
}


.web-profile-button{
  margin-top: 35%;
  font-size: 36px;
}


.sidebar {
  width: 15%;
  background-color: $textBlackColor;
  display: grid;
  position: fixed;
  left: 0;
  grid-template-rows: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.sidebar-mobile {
  background-color: $textBlackColor;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.sidebar-title {
  text-align: center;
  color: #fff;
  align-self: center;
  font-size: 20px;
  border-bottom: 1px solid $greyColor;
  display: grid;
  text-align: center;
  align-items: center;
}

.sidebar-logo-container {
  height: 40px;
  width: 40px;
  display: grid;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  margin: 11px;
}

.sidebar-item-container {
  width: 100%;
}

.icon-sidebar-layout {
  justify-content: center;
  display: grid;
  grid-template-columns: 30% 50%;
  grid-column-gap: 5px;
  text-align: start;
  padding: 10px 10%;
  transition: 300ms;
  color: rgb(169, 180, 180);
}

.icon-sidebar-layout:hover {
  background: rgb(0, 0, 0);
  color: #fff;
}

.icon-sidebar-layout:focus {
  background: rgb(0, 0, 0);
  color: #fff;
}

.icon-sidebar-layout-mobile {
  height: 100%;
  display: grid;
  text-align: start;
  padding: 20px 0px;
  align-content: center;
  justify-content: center;
  color: rgb(251, 255, 255);
}

.icon-sidebar-layout-mobile:hover {
  height: 100%;
  display: grid;
  text-align: start;
  padding: 20px 0px;
  background: rgb(206, 221, 218);
  align-content: center;
  justify-content: center;
  color: rgb(11, 12, 12);
}

a {
  width: 100%;
}

a:hover {
  text-decoration: none;
}

.text-sidebar {
  text-align: start;
}

.icon-sidebar {
}

.icon-sidebar:hover {
}

.topbar {
  width: 85%;
  height: 10%;
  background: #fff;
  display: grid;
  position: fixed;
  top: 0;
  grid-template-columns: 86% 10%;
  @include shadow();
}

.topbar-mobile {
  width: 100%;
  height: 10%;
  background: #fff;
  display: grid;
  position: fixed;
  top: 0;
  grid-template-columns: 15% 65% 20%;
  @include shadow();
}

.topbar-pagetitle{
  text-align: center;
  padding-top: 7%;
}

.topbar-menu {
  background: #fff;
  justify-self: right;
  padding: 5px 10px;
  border-radius: 0px;
  align-self: center;
  justify-self: end;
}

.topbar-profile {
  background: #fff;
  justify-self: right;
  padding: 5px 10px;
  border-radius: 0px;
  align-self: center;
  justify-self: end;
}

.icon-topbar {
  border: 1px solid $textBlackColor;
  border-radius: 3px;
  align-self: center;
  justify-self: center;
  padding: 10px;
  transition: 300ms;
}

.icon-topbar:hover {
  background: $textBlackColor;
  color: #fff;
}

.btn-logout {
  background: #fff;
  color: $textBlackColor;
  padding: 3px;
  width: 100%;
  outline: none;
  border: none;
}



.profile-container{
  padding: 10px;
}

.text-profile{
  margin: 10px;
}



header.ant-layout-header{
  padding: 0 20px 10px 0px;
}


span.ant-calendar-picker{
  width: 100%;
}

.app-logo{
  height: 35px;
}

.topbar-container{
  @include shadow();
}

