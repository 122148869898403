@import "../../assets/styles/constants.scss";

// ant design default

.demo-infinite-container {
    border: 1px solid #e8e8e8;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 500px;
    // height: 100px;
  }
  .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .icon-delete-notice{
    margin-left: 90%;
    color: darkslategrey;
  }

  .icon-delete-notice:hover{
    color: crimson;
  }

@media(max-width: 600px){
  .demo-infinite-container {
      height: 400px;
  }
}

